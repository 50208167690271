@import '../../styles/utilities/variables';

a {
  &.gradient-button {
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
  }

}

.gradient-button.disabled:hover span{
  display: none;
}

.gradient-button.disabled:focus span{
  display: none;
}

.gradient-button.disabled:hover, .gradient-button.disabled:focus {
  cursor: not-allowed;

   &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 0;
 
  }

  &::after {
    content: "Coming Soon";
    position: absolute;
    left: 0;
    top: -3px;
    right: 0;
    bottom: 0;
    color: white;
    text-align: center;
    line-height: 65px; /* You can adjust this */
    font-size: 16px;
    z-index: 3;
    font-weight: 900;
    transition: .3 ease;
    

    &:hover, &:focus {
      scale: 1.2;
    }
  }
}


.gradient-button {
    width: 279px;
    height: 60px;
    border: none;
    cursor: pointer;
    color: white;
    border-radius: 5px;
    font-size: 16px;
    transition: .3s scale ease;

    font-family: $base-font-family;
    font-size: 20px;
    font-weight: 700;

    &:hover {
      scale: 1.025;
      font-weight: 700;
    }

    &.blue-purple {
      background: $button-blue-purple;

      &:hover {
        background: $button-blue-purple-hover;

      }
    }

    &.purple {
      background: $link-color;
    }
  
    &.purple-blue {
      background: $button-purple-blue;
      
      &:hover {
        background: $button-purple-blue-hover;          
      }
    }
  
    &.orange-pink {
      background: $button-orange-pink;
      
      &:hover {
        background: $button-orange-pink-hover;         
      }
    }   
  }
  
/* Colors */
$button-blue-purple: linear-gradient(95deg, #6060F2 0%, #B0ACEB 100%);
$button-blue-purple-hover: linear-gradient(270deg, #6060F2 0%, #B0ACEB 100%);
$button-purple-blue: linear-gradient(95deg, #9D4F9D 0%, #6060F2 100%);
$button-purple-blue-hover: linear-gradient(270deg, #9D4F9D 0%, #6060F2 100%);
$button-orange-pink: linear-gradient(95deg, #F05F47 0%, #DE3593 100%);
$button-orange-pink-hover: linear-gradient(270deg, #F05F47 0%, #DE3593 100%);
$text-gradient: linear-gradient(to right, #FBB315, #F05F47, #DE3593, #9D4F9D);

$white: #FFF;
$black: #000;

$link-color: #6060F2;
$link-hover-color: #B0ACEB;

/* Layout */
$padding-large: 230px; // for >= 1920px
$padding-medium: 130px; // for 1440px
$padding-small: 30px; // for <= 375px
$max-width: 1440px;

/* Fonts */
$base-font-family: 'Red Hat Display', sans-serif;
  
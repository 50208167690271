.input-container {
    display: flex;
    align-items: center;
    height: 48px; 
    padding: 0 16px;
    gap: 12px;
    background: linear-gradient(0deg, #363A3D, #363A3D),
                linear-gradient(0deg, #1A1D21, #1A1D21);
    border-radius: 8px;
    border: 1px solid #363A3D;
}
  
  .text-input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-align: left;
    color: #FFFFFF; /* Assuming the text color is white */
  }
  
  .text-input::placeholder {
    color: #A0A0A0; /* Placeholder text color, adjust as needed */
  }
  
  /* Make sure the input box does not have an inset shadow in iOS */
  .text-input:focus {
    border-radius: 8px;
  }
  
@import '../../../styles/utilities/variables';

.week5{
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 75%;
    margin-bottom: 100px;

    h1{
        font-family: $base-font-family;
        color: white;
        font-weight: 800;
        font-size: 50px;
        margin-bottom: 50px;
    }
    .button-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 35px;
    
    }
}
.modal-container {
    position: absolute;
    background-color: rgba(217, 217, 217, 0.86);
    border-radius: 7px;
    z-index: 1000;
    overflow-y: auto;
    max-width: 1099px;
    max-height: 685px;

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px; 
        border: none;
        background: transparent;
        font-size: 20px; 
        cursor: pointer;
        z-index: 1;
      }
  }
  
 
@import '../../../styles/utilities/variables';

.week3{
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 75%;
    margin-bottom: 100px;

    h1{
        font-family: $base-font-family;
        color: white;
        font-weight: 800;
        font-size: 50px;
        margin-bottom: 20px;
    }
    .button-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 35px;
        .puzzle{
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 7px;
            width: 100%;
            max-width: 400px;
            background-color: #6060f2;
            padding: 3px;

            p{
                font-family: $base-font-family;
                font-size: 20px;
                font-weight: 700;
                line-height: 27px;
                letter-spacing: 0em;
                text-align: center;
                color: white;
                margin: 6px;
            }
            .circle-wrapper{
                display: flex;
                margin-bottom: 5px;
                gap: 5px;
                p {
                    font-weight: 700;
                    font-size: 20.4px;
                    color: white;
                    font-family: $base-font-family;
                    line-height: 27px;
                }
                div{
                    width: 10px;     
                    height: 10px;      
                    border-radius: 50%; 
                    background-color: white 
                  }
            }
           
        }
    }
    .word-wrapper{
        display: flex;
        flex-direction: row;
        max-width: 600px;
        margin-top: 50px;
        gap: 20px;

        button{
            height: 48px;
        }
        &__field{
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        &__label{
            display: flex;
            align-items: center;
            
            img{
                width: 20px;
            }

            p{
                color: #C3C9CF;
                font-family: 'RoadRadio', sans-serif;
                font-weight: 400;
            }
        }
    }
}
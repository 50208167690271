.preloader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    background-color: #030046;
  }
  
  .circle-spinner {
    width: 100px; 
    height: 100px; 
    border-radius: 50%;
    background-image: linear-gradient(to right, #FBB315, #F05F47, #DE3593, #9D4F9D);
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
@import '../../../styles/utilities/variables';

.week3{
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 75%;
    margin-bottom: 100px;

    h1{
        font-family: $base-font-family;
        color: white;
        font-weight: 800;
        font-size: 50px;
        margin-bottom: 20px;
    }
    .button-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 35px;
        .puzzle{
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 7px;
            width: 100%;
            max-width: 400px;
            background-color: #6060f2;
            padding: 3px;

            p{
                font-family: $base-font-family;
                font-size: 20px;
                font-weight: 700;
                line-height: 27px;
                letter-spacing: 0em;
                text-align: center;
                color: white;
                margin: 6px;
            }

            a{
                text-decoration: none;
                color: white;
                font-family: $base-font-family;
                font-size: 20px;
                font-weight: 700;
            }
           
        }
    }
}
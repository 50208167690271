@import "../../styles/utilities/variables";

.App-home {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    background-image: url('../../assets/sections/home/00_BG.png');
    position: relative;
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  .App-home__button {
    position: absolute;
    bottom: 3%; 
  }

  .video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #282c34;
    width: 100%;
    height: 100vh;
}

.video-container video {
   width: 100%;
   height: 100%;
   object-fit: cover;   
}

.modal-content {
  text-align: center;
  color: #000; 
  border-radius: 7px;
  opacity: 0.86;
  padding: 4%;
  z-index: 1000; 
}

.modal-text {
  font-family:  'RoadRadio', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 51px;

  &__small{
    font-family:  'RoadRadio', sans-serif;
    font-size: 10px;
  }
  &:last-child {
    margin-bottom: 0; 
  }
}

.modal-description {
  font-family:  'RoadRadio', sans-serif;
  font-weight: 400;
  font-size: 17px;
}

.gameGif {
  max-width: 100%; 
  max-height: 100%;
}


.download-button{
  margin: 10px;
  position: absolute;
  right: 0;
  top: 0;
  font-family: 'RoadRadio', sans-serif;
  font-size: medium;
  width: 150px;
  height: 25px;
  color: white;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  background: #2C2F48
}

.provider-button{
  cursor: pointer;
  background-color: black;
  color: white;
  margin: 5px;
  border: none;
  height: 40px;
  width: 150px;
  border-radius: 20px;

  div{
    height: 30px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.modal-link {
  color: inherit; // Inherits the color from the parent element
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
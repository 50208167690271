@import "../../styles/utilities/variables";

header {
  background-color: transparent;
   height: 100px;
   width: 100%;
   z-index: 999;
   position: sticky;

  @media (max-width: 768px) {
  
    width: 100%;
    background: #030046;
    z-index: 222;
    border-bottom: 1px solid #6060f2;
  }

  .header-container {
    
    padding: 3%;

    @media (max-width: 768px) {
      padding: 0;
    }

    .header-content-container.desktop {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 40px;
      gap: 32px;

      .back-wrapper{
        font-family: 'RoadRadio', sans-serif;
        margin-left: 10%; 
        color: white;
        transition: color 0.3s ease;
      }


      .back-wrapper:hover {
        color: white;
        cursor: pointer;
      }

      .logo-wrapper {
        img {
          max-width: 100%;
        }
      }

      .button-wrapper {
        display: flex;
        gap: 20px;
        max-width: 60%;

        button,
        a {
          max-width: 180px;
        }
      }
    }

    .header-content-container.mobile {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding-top: 35px;
      align-items: center;

      .logo-wrapper {
        img {
          width: 160px;
          height: 32px;
        }
      }

      .back-wrapper{
       
        margin-left: 10%;
      }

      .icon-wrapper {
        justify-self: self-end;
        display: flex;
        gap: 10px;
      }
    }
  }
}

.word-wrapper{
    display: flex;
    flex-direction: row;
    max-width: 600px;
    margin-top: 50px;
    gap: 20px;

    button{
        height: 48px;
    }
    &__field{
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__label{
        display: flex;
        align-items: center;
        
        img{
            width: 20px;
        }

        p{
            color: #C3C9CF;
            font-family: 'RoadRadio', sans-serif;
            font-weight: 400;
        }
    }
}
@import "../../styles/utilities/variables";
.week-page {
    min-height: 100vh;
    background-color: #030046;
  
    position: relative;
    &__title{
        margin: 0;
        font-family: 'RoadRadio', sans-serif;
        font-weight: 700;
        color: white;
        line-height: 70px;
        text-align: center;
    }
    &__week {
        margin: 0;
        font-family: 'RoadRadio', sans-serif;
        font-weight: 700;
        font-size: 50px;
        line-height: 70px;
        text-align: center;
        background: $text-gradient;   
        
        background-clip: text;
        color: transparent;
        display: inline-block; 
    }
    
    

}

.week-page__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
}

.week-page__social-media{
    position: absolute;
    z-index: 1;
    
    display: flex;
    justify-content: center;
    bottom: 18px;
    gap: 65px;
    left: 50%;
    transform: translateX(-50%);

    img{
        cursor: pointer;
    }
}


.background-image-wrapper {
    position: fixed;
    top: 280px;
    left: 0;
    width: 100%;
    height: 63%;
    z-index: 0;
    transform: scaleX(-1);   
  }
  
  .background-image-wrapper img {
    opacity: 0.5;
    width: 100%;
    height: 100%;
    object-fit: cover; 
    transform: translateY(20%); 

    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  
  }

  .week-home__button {
    display: flex;
    justify-content: center; /* Centers the button horizontally */
    margin-top: 20px; /* Add some space above the button */
    z-index: 9999;
  }
  
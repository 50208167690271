.circle-container {
    width: 390px;
    height: 600px;
    position: relative;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
  }
  
  .circle {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .circle-button {
    position: absolute;
    width: 30px;
    background-color: transparent;
    height: 30px;
    border: none;
    border-radius: 50%;
    transform-origin: 50% 50%;

    &--enabled {
      cursor: pointer;
    }
  
  }

  .centered-gif{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    height: 600px;
    object-fit: cover;
  }
  
  .button-0 { transform: rotate(254deg) translate(183px) rotate(-240deg); }
  .button-1 { transform: rotate(281deg) translate(185px) rotate(-270deg); }
  .button-2 { transform: rotate(307deg) translate(179px) rotate(-300deg); }
  .button-3 { transform: rotate(335deg) translate(175px) rotate(-330deg); }
  .button-4 { transform: rotate(5deg) translate(163px) rotate(0deg); }
  .button-5 { transform: rotate(36deg) translate(151px) rotate(-30deg); }
  .button-6 { transform: rotate(69deg) translate(147px) rotate(-60deg); }
  .button-7 { transform: rotate(104deg) translate(147px) rotate(-90deg); }
  .button-8 { transform: rotate(138deg) translate(152px) rotate(-120deg); }
  .button-9 { transform: rotate(170deg) translate(162px) rotate(-150deg); }
  .button-10 { transform: rotate(200deg) translate(171px) rotate(-180deg); }
  .button-11 { transform: rotate(228deg) translate(183px) rotate(-210deg); }

  